<template>
    <div style="text-align: center;">
        <van-popup v-model="show" position="top" :overlay="false" :style="{background: background}">
            <van-nav-bar
                    placeholder
                    fixed
                    left-arrow
                    :border='false'
                    z-index="99"
                    class="header-reading"
                    @click-left="returnClick">
                <template #title>
                    <span :class="background==Color?'active':''">{{readList.title}}</span>
                </template>
                <template #left>
                    <van-icon name="arrow-left" size="25" color="#fff" v-if="background==Color"/>
                    <van-icon name="arrow-left" size="25" color="#0A1C33" v-else/>
                </template>
            </van-nav-bar>
        </van-popup>
        <!--        文章显示-->
        <div
                class="reading"
                :style="{background: background,}"
                ref="scroll"
        >
            <div @click="readingClick" :class="background==Color?'active':''"
                 :style="{'font-size':fontSize+'px','line-height':lineHeight+'px','word-wrap':'break-word'}"
                 v-html="readList.info">
            </div>
            <template v-if="readList.info">
                <div>
                    <div class="is_pay" @click="onCLickAvatar">
                        <p class="is_pay_title">本章需要 点击<span>立即获取小说币</span>阅读</p>
                        <img src="../../assets/images/read_menu_pay_tit.png" alt="" class="read_menu_pay_tit">
                    </div>
                </div>
            </template>

            <ReadingList @directoryClick="directoryClick" @preId="preId" @nextId="nextId"
                         :background='background'
                         :Color="Color"
                         v-if="readList.info"></ReadingList>
        </div>
        <!--        侧边目录-->
        <van-popup v-model="show_left" position="left" style="height: 100%;width: 75%;background: #E7E7E7">
            <Left :id="id" :chapterList='chapterList' :end='end' :chaps='readList.chaps'
                  @chapsClick="chapsClick" :background='background'
                  :Color="Color"
                  :user_infoList='user_infoList'
                  :style="{background: background}"></Left>
        </van-popup>
        <!--        底部设置-->
        <van-popup v-model="show" position="bottom" :overlay="false" class="button-reading"
                   :style="{background: background}">
            <div class="mask">
                <Button @leftClick="leftClick" @backgroundClick="backgroundClick"
                        @fontSizeClick="fontSizeClick" :value="valid"
                        @preId="preId" @nextId="nextId" :background="background" :Color="Color"
                        @switchClick="switchClick" :checked=checked
                        :user_infoList="user_infoList"
                ></Button>
            </div>
        </van-popup>
        <!--        阅读时间-->
        <!--
        <van-popup v-model="show" position="right" :overlay="false" class="reading-right"
                   :style="{background: background}">
            <div class="right" :class="background == Color?'active':''">
                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon"/>
                已阅读{{base_infoList.today_read_time}}分钟
                <van-count-down :time="time" format="ss" ref="countDown" @change="timeClick" @finish="finishClick"
                                style="display:none;"/>
            </div>
        </van-popup>
        -->
        <!--        书豆余额-->
        <!--
        <van-popup v-model="show" position="right" :overlay="false" class="reading-right dou-right"
                   :style="{background: background}">
            <div class="right" :class="background == Color?'active':''">
                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon"/>
                {{base_infoList.coin}}
            </div>
        </van-popup>
        -->
        <!--        付费章节购买-->
        <!--
        <van-popup v-model="show_pay" position="bottom" closeable v-if="user_infoList.is_vip != '1'">
            <Pay :chapterList='chapterList' :readList="readList" @payList="payList" @topupClick="topupClick"
                 @buttonClick="buttonClick"></Pay>
        </van-popup>
        -->
        <van-popup v-model="logo_show" position="bottom">
          <div class="anima">
              <van-button type="primary" size="large" round class="logo-button" style="width: 90%;" @click="onCLickAvatar">立即下载小说家阅读更多</van-button>
          </div>
        </van-popup>

        <div class="anima" style="position: fixed;right: 0px;bottom: 20%;width: 75px;height: 75px;text-align: center;" @click="onCLickAvatar" >
             <img src="../../assets/images/baoxiang.png" style="width: 100%;" >
        </div>

    </div>
</template>
<script>
  import Button from '../../components/ReadingView/Button_new'
  import Left from '../../components/ReadingView/Left'
  import ReadingList from '../../components/ReadingView/ReadingList'
  import Pay from "../../components/ReadingView/Pay";
  import axios from "axios";

  export default {
    components: {
      Button,
      Left,
      ReadingList,
      Pay,
    },
    name: "Reading",
    data() {
      return {
        Focus_show: false,
        Focus_url: '',
        is_subscribe: '',
        generalize: '',
        subchaps: '',
        msg:'',
        Color: '#543131',
        show: false,
        show_left: false,
        show_pay: false,
        logo_show: false,
        background: '#E7E7E7',
        fontSize: Number,
        lineHeight: Number,
        valid: Number,
        id: this.$route.query.id,
        readList: [],
        chapterList: [],
        end: [],
        chaps: 5,
        base_infoList: [],
        time: 60 * 1000,
        time_total: 0,
        times: 0,
        Time: 60,
        checked: Boolean,
        user_infoList: [],
        self_time: 0,
        userAgent: true,
        code: '',
      }
    },
    created() {
      this.self_time = parseInt(new Date().getTime() / 1000);
      if (this.$route.query.generalize) {
        this.generalize = this.$route.query.generalize
        this.subchaps = this.$route.query.subchaps
      } else {
      }
      this.chaps = this.$route.query.chaps
      if (this.generalize && localStorage.getItem('token')) {
        // 公众号二维码
        this.is_subscribe_wechat_mp()
        this.wechat_subscribe_qrcode()
      } else {
        this.readClick()
      }
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        if (this.generalize) {
          //在微信中
          this.userAgent = false
          if (!localStorage.getItem('token')) {
            this.code = this.GetUrlParam('code')
            if (this.code) {
              this.getOpenId(this.code)
            } else {
              this.WeiXin()
            }
          }
        }
      } else {
        //在浏览器中打开
        this.userAgent = false
        localStorage.setItem('generalize', this.generalize)
      }
      this.user_info()
      this.locationClick()
      this.chapterPOST()
      this.base_info()
    },
    destroyed() {
      this.count_read_time2();
    },
    methods: {
      wechat_subscribe_qrcode() {
        this.$axios.post('/api/user/wechat_subscribe_qrcode', {
          scene: 'anime_generalize',
          scene_data: {'generalize': this.generalize}
        })
          .then(res => {
            this.Focus_url = res.data.data.data.url
          })
      },
      is_subscribe_wechat_mp() {
        this.$toast.loading({
          message: '加载中...',
          duration: 0,
          overlay: true,
        });
        this.$axios.post('/api/user/is_subscribe_wechat_mp')
          .then(res => {
            this.$toast.clear()
            this.is_subscribe = res.data.data.is_subscribe
            this.readClick()
          })
      },
      // 授权登录
      GetUrlParam(name) {//封装方法

      },
      // 登录
      WeiXin() {

      },
      getOpenId(code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口

      },
      // 授权登录 end
      user_info() {

      },
      returnClick() {

      },
      topupClick() {

      },
      buttonClick() {

      },
      // 开启自动付费章节
      switchClick(checkbox) {

      },
      timeClick(e) {

      },
      finishClick() {

      },
      // 购买章节
      payList() {

      },

      // 阅读时间
      count_read_time2(e) {
        this.Time = this.Time - this.times
        var time_send = parseInt(new Date().getTime() / 1000) - this.self_time;
        this.$axios.get('/api/bookstores/count_read_time', {
          params: {
            time: time_send
          }
        })
          .then(res => {
            this.Time = 60
            this.time_total = 0
            if (!e) {
              this.$refs.countDown.reset();
            }
            this.base_info()
          })
      },
      // 阅读时间
      count_read_time(e) {
        return;
        this.Time = this.Time - this.times
        this.$axios.get('/api/bookstores/count_read_time4', {
          params: {
            time: this.Time
          }
        })
          .then(res => {
            this.Time = 60
            this.time_total = 0
            if (!e) {
              this.$refs.countDown.reset();
            }
            this.base_info()
          })
      },
      base_info() {
        this.$axios.get('/api/welfare/base_info')
          .then(res => {
            this.base_infoList = res.data.data
          })
          .catch(err => {
            this.$toast(err.response.data.message);
          })
      },
      nextId() {
        this.show = false
        this.chaps = this.readList.nextId
        if (!this.chaps) {
          this.$toast('已经是最后一页了')
          return
        }
        this.readClick()
        this.count_read_time()
      },
      preId() {
        this.show = false
        this.chaps = this.readList.preId
        if (!this.chaps) {
          this.$toast('已经是第一页了')
          return
        }
        this.readClick()
        this.count_read_time()
      },
      chapsClick(item) {
        this.chaps = item.chaps
        this.readClick()
        this.show_left = false
      },
      chapterPOST() {
        this.$axios.post('/api/bookstores/chapter', {
          anime_id: this.id
        })
          .then(res => {
            if (this.readList.coin != 0 && this.readList.is_pay == 0) {
              if (!localStorage.getItem('token')) {
                this.logo_show = true;
              }else{
                this.show_pay = false
              }
            } else {
              this.show_pay = false
            }

            var res = res.data.data
            this.chapterList = res
            this.end = this.chapterList.chapters[this.chapterList.chapters.length - 1]
          })
          .catch(err => {
            this.$toast(err.response.data.message);
          })
      },
      readClick() {
        if(this.chaps > this.subchaps){
            this.logo_show = true;
        }else{
            if (this.id == sessionStorage.getItem("anime_id")) {
              this.chaps = sessionStorage.getItem("chaps");
              sessionStorage.removeItem("chaps");
              sessionStorage.removeItem("anime_id");
            }
            this.$axios.post('/api/bookstores/read', {
              anime_id: this.id,
              chaps: this.chaps,
            })
            .then(res => {
                this.$refs.scroll.scrollTop = 0;
                var res = res.data.data
                res.info = this.html_encode(res.info)
                console.log(res);
                this.readList = res
                if (this.user_infoList.is_vip != '1') {
                  if (this.readList.coin != 0 && this.readList.is_pay != '1') {
                    if (this.checked) {
                      this.payList();
                      return
                    }
                    if (!localStorage.getItem('token')) {
                      this.logo_show = true;
                      sessionStorage.setItem('chaps', 5);
                      sessionStorage.setItem('anime_id', this.id);
                      return
                    }else{
                      this.show_pay = true
                    }
                    this.readList.info = this.readList.info.substr(0, 300)
                  }
                }
                if (this.generalize) {
                  if (this.readList.chaps >= this.subchaps) {
                        this.logo_show = true;
                  }
                }
          })
        }
      },
      html_encode(str) {
        var s = "";
        if (str.length == 0) return "";
        s = str.replace(/&/g, "&amp;");
        s = s.replace(/</g, "&lt;");
        s = s.replace(/>/g, "&gt;");
        s = s.replace(/ /g, "&nbsp;");
        s = s.replace(/\'/g, "&#39;");
        s = s.replace(/\"/g, "&quot;");
        s = s.replace(/\n/g, "<br/>");
        return s;
      },
      directoryClick() {
        this.show_left = true
      },
      locationClick() {
        if (localStorage.getItem('checked') == '1') {
          this.checked = true
        } else {
          this.checked = false
        }
        if (localStorage.getItem('background')) {
          this.background = localStorage.getItem('background')
        } else {
          this.background = '#E7E7E7'
        }
        if (localStorage.getItem('fontSize')) {
          this.fontSize = Number(localStorage.getItem('fontSize'))
          this.valid = this.fontSize
          this.lineHeight = this.fontSize + 10
        } else {
          this.fontSize = 18;
          this.valid = this.fontSize
          this.lineHeight = this.fontSize + 10
        }
      },
      add_read_history() {
        this.$axios.post('/api/bookstores/add_read_history', {
          anime_id: this.id,
          chaps: this.readList.chaps
        })
          .then(res => {
          })
      },
      readingClick() {
        this.show = !this.show
      },
      leftClick() {
        this.show = false
        this.show_left = true
      },
      backgroundClick(item) {
        this.background = item
      },
      fontSizeClick(value) {
        this.fontSize = value
        this.valid = this.fontSize
        this.lineHeight = this.fontSize + 20
      },
      onCLickAvatar() {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if(isiOS){
            this.$toast('工程师正在开发中，苹果手机用户暂不支持下载~')
        }else{
            window.location.href= this.$route.query.openurl;
        }   
      },
      upClick() {
        if (!localStorage.getItem('token')) {
          if (this.userAgent) {
            this.WeiXin()
          } else {
            this.$router.push({
              path: '/login'
            })
          }
          return
        }
        this.$router.push({
          path: '/up'
        })
      },
      login() {
        if (this.userAgent) {
          this.WeiXin()
        } else {
          this.$router.push({
            path: '/login'
          })
        }
      },
    }
  }
</script>
<style scoped>
    .share_arrows_div {
        position: fixed;
        top: 40px;
        right: 60px;
        color: #fff;
        text-align: right;
    }

    .share_arrows {
        width: 107px;
        height: 170px;
        margin-bottom: 30px;
    }

    .wrapper_title {
        text-align: center;
        font-size: 36px;
        padding: 30px 0 0 0;
    }

    .wrapper_subtitle {
        font-size: 30px;
        text-align: center;
        padding: 30px 0 0 0;
    }

    .Focus_url_img {
        width: 550px;
        height: 550px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    .block {
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .is_pay_title > span {
        color: #FCAD0F;
    }

    .is_pay {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 100px 0;
    }

    .is_pay_title {
        font-size: 32px;
        color: #606266;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }

    .read_menu_pay_tit {
        height: 17px;
    }

    .active {
        color: #fff !important;
    }

    .reading-right {
        border-radius: 30px 0 0 30px;
        top: 300px;
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.2);
    }

    .dou-right {
        top: 200px;
    }

    .right {
        font-size: 24px;
        padding: 20px 40px 20px 30px;
        color: #303133;
        display: flex;
        align-items: center;
    }

    .bookbean_icon {
        width: 19px;
        height: 20px;
        margin-right: 10px;
        display: inline-block;
    }

    .button-reading {
        box-shadow: 0px 0px 18px 0px rgba(132, 134, 156, 0.1);
    }

    .mask {
        padding: 50px 40px;
    }

    .reading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        padding: 40px;
        text-align: justify;
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    }

    .logo-button {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .anima {
      animation-name: likes;
      animation-direction: alternate; 
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-duration: 1s; 
    }
  
    @keyframes likes {
      0%{
            transform: scale(1);
        }
        25%{
            transform: scale(0.9);
        }
        50%{
            transform: scale(0.85);
        }
        75%{
            transform: scale(0.9);
        }
        100%{
            transform: scale(1);
        }
    }
</style>